<template>
    <div class="home">
        <img class="logo" src="@/assets/mobile/logo@2x.png" alt="">
        <img class="main" src="@/assets/mobile/main@2x.png" alt="">
        <div @click="start" class="bottom flex-between">
            <div class="start">开始</div>
            <img class="up" src="@/assets/mobile/up@2x.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        start() {
            this.$router.push('/about')
        }
    }
}
</script>

<style lang="less" scoped>
.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home {
    padding: .32rem;
    .logo {
        width: 2rem;
        display: block;
        text-align: left;
    }
    .main {
        width: 6.86rem;
        margin-top: .24rem;
        display: block;
    }
    .bottom {
        width: 6.22rem;
        height: 1.12rem;
        background: #45B79C;
        box-shadow: 0 .08rem .28rem 0 rgba(69,183,156,0.48);
        border-radius: .56rem;
        margin-top: .4rem;
        padding: 0 .32rem;
        .start {
            margin-left: .24rem;
            font-size: .52rem;
            color: #FFFFFF;
            font-weight: bold
        }
        .up {
            width: .64rem;
        }
    }
}

</style>

